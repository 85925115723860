import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { CheckIcon, MicrophoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ContentItemType, iMessage } from './iMessage'
import './mermaid-custom.css'
import MonacoEditor from '@uiw/react-monacoeditor'
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon'
import FileViewer from '../sessionControls/files/fileViewer.tsx'
import { iChatMessageProps } from './iChatMessage.tsx'
import FeedbackWidget from './feedback/feedbackWidget.tsx'
import knowledgeContainerService from '../../services/knowledgeContainerService.ts'
import PdfViewer from './sourceFiles/pdfViewer.tsx'
import sessionService from '../../services/sessionService.ts'
import { useMessages } from '../../stateManagement/contexts/messageContext.tsx'
import { iSession } from './iSession.ts'
import {
  iCitation,
  iFile,
  iKnowledgeReference,
  iSourceFileCitation,
  ViewerType,
} from './sourceFiles/iSourceFileCitations'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { Button, Spinner, Tab, Tabs } from '@nextui-org/react'
import './chatMessage.css'
import { ToolCall } from './toolCalls/toolCall.tsx'
import ChatMessageHeader from './chatMessageHeader.tsx'
import 'katex/dist/katex.css'
import { shallow } from 'zustand/shallow'
import Markdown from './Markdown.tsx'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'

function ChatMessage({
  message,
  sessionId,
  userId,
  transfered,
  isLastMessage,
}: iChatMessageProps) {
  const { sessions } = useSessionStore(
    (state) => ({ sessions: state.sessions }),
    shallow,
  )

  const { updateMessage, deleteMessage } = useMessages()
  const { error } = useMain()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editedMessage, setEditedMessage] = useState(message.content)
  const [messageContent, setMessageContent] = useState([] as string[])
  const [contentLoaded, setContentLoaded] = useState(false)
  const [references, setReferences] = useState<iKnowledgeReference[]>()
  const [activeReferenceId, setActiveReferenceId] = useState<number>()
  const session = sessions.find((session) => session.id === sessionId)
  const [displayToolCallsTab, setDisplayToolCallsTab] = useState(false)
  const [displaySourceFileTab, setDisplaySourceFileTab] = useState(false)
  const [selected, setSelected] = useState<string>('conversation')

  useEffect(() => {
    setDisplayToolCallsTab(!!message.toolCalls && message.toolCalls.length > 0)
  }, [message.toolCalls])

  const displayFeedback = useMemo(() => {
    return !session?.isGenerating
  }, [session?.isGenerating])

  useEffect(() => {
    setDisplaySourceFileTab(
      !!(
        !session?.isGenerating &&
        message.toolCalls &&
        message.citations &&
        message.citations.files?.length > 0
      ),
    )
  }, [message.toolCalls, message.citations])

  useEffect(() => {
    setContentLoaded(false)
    convertMessage(message).then((content) => {
      setMessageContent(content!)
      setContentLoaded(true)
    })
  }, [message.content, message.contentItems])

  const messageError = useMemo(() => {
    if (session?.isGenerating && isLastMessage) return ''
    if (message.error) return message.error.message
    if (!messageContent.length && message.id && contentLoaded)
      return 'Empty answer from Backend'
    // error messages just for the last message
    if (!isLastMessage) return
    if (!isLastMessage) return
    if (error) return `${error} ||->> Error disappears after refreshing session`
    return ''
  }, [message, messageContent, error])

  const getReferenceContent = (refId: number): string => {
    let content = ''
    message.citations?.files.forEach((file: iFile) => {
      file.citations.forEach((citation) => {
        if (citation.citationId === refId) {
          content = citation.citation
        }
      })
    })
    return content
  }

  useEffect(() => {
    if (selected === 'sourceFiles') {
      addDocumentAccess().then((allToolCitations) =>
        updateReferences(allToolCitations),
      )
    }
  }, [displaySourceFileTab, selected])

  const addDocumentAccess = () => {
    return sessionService.getSourceFiles(sessionId, message.id!)
  }

  const updateReferences = (sourceFileCitation: iSourceFileCitation) => {
    const refs: iKnowledgeReference[] = []
    sourceFileCitation.files.forEach((file) => {
      file.citations.forEach((citation) => {
        const content = getReferenceContent(citation.citationId)
        if (!content) {
          return
        }
        refs.push({
          id: citation.citationId,
          documentAccess: {
            accessUrl: file.accessUrl,
            documentName: file.documentName,
            mimeType: file.mimeType,
            viewerType: file.viewerType,
          },
          container: file.container,
          page: citation.page,
          content: content,
        })
      })
    })
    setReferences(refs)
  }

  const showCitation = (citation: iCitation | null) => {
    if (!citation) {
      return
    }
    addDocumentAccess().then((allToolCitations) => {
      const file = allToolCitations.files.find((file: iFile) => {
        return file.citations.find((c) => c.citationId === citation.citationId)
      })
      if (!file) {
        return
      }
      if (file.viewerType === ViewerType.HttpNewTab) {
        window.open(file.accessUrl, '_blank')
        return
      }
      if (file.viewerType === ViewerType.GaiaViewer) {
        updateReferences(allToolCitations)
        setSelected('sourceFiles')
        setActiveReferenceId(citation.citationId)
      }
    })
  }

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSaveEdit = async () => {
    message.content = editedMessage
    setIsEditing(false)
    await updateMessage(session as iSession, message)
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
  }

  const handleDelete = async () => {
    await deleteMessage(session as iSession, message)
  }

  function isDefaultExtensionType(key: string): key is DefaultExtensionType {
    return key in defaultStyles
  }

  const convertMessage = async (message: iMessage): Promise<string[]> => {
    if (message.content) {
      return [message.content]
    }

    if (message.contentItems) {
      return await Promise.all(
        message.contentItems.map(async (item) => {
          if (item.type === ContentItemType.Text) {
            return item.content
          }
          if (item.type === ContentItemType.Image) {
            try {
              const imageBlob = await knowledgeContainerService.downloadImageBlob(
                item.content,
              )
              return await readAsDataURL(imageBlob)
            } catch (error) {
              return ''
            }
          }
          return ''
        }),
      )
    }
    return []
  }

  const readAsDataURL = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(`<img class="object-scale-down" src="${reader.result}"/>`)
      }
      reader.onerror = () => reject('Failed to read blob as data URL.')
      reader.readAsDataURL(blob)
    })
  }

  const customStyle: CSSProperties = {
    '--color-canvas-default': 'transparent',
    // "--color-fg-default": "var(--bc)",
    // '--color-canvas-subtle': '#1f2937',
    margin: '10px',
    textAlign: 'left',
    fontFamily: 'PangeaText',
    fontSize: '14px',
    overflowX: 'auto',
  } as CSSProperties

  const chatClass = useMemo(() => {
    return message.ownerId === userId && message.role === 'user'
      ? 'chat-end'
      : 'chat-start'
  }, [])

  if (message.role === 'system') {
    return <></>
  }

  if (message.contentType === 'contentMessage') {
    return (
      <div>
        <div
          className="flex justify-center items-center m-2"
          style={{ userSelect: 'none' }}
        >
          {/* <button className="btn glass btn-xs"> */}
          <div
            className="shadow-xl w-fit rounded-lg bg-base-100 hover:bg-base-200 hover:shadow-md transition duration-200 ease-in-out transform border-secondary border-t-4"
            onClick={handleExpand}
          >
            {message.filesData && message.filesData.length > 0 ? (
              <div className="flex justify-center items-center space-x-4">
                <div style={{ width: '48px', margin: '16px' }} className="m-4 stack">
                  {message.filesData?.map((file, index) =>
                    isDefaultExtensionType(file.extension) ? (
                      <FileIcon
                        extension={file.extension}
                        // {...defaultStyles[file.extension]}
                        key={index}
                      />
                    ) : (
                      <FileIcon extension={file.extension} key={index} /> // Fallback without styles
                    ),
                  )}
                </div>
                <h2 className="text-xl font-bold text-center text-primary">
                  {message.filesData?.length > 1
                    ? message.filesData?.length + ' Files'
                    : message.filesData?.[0].name}
                </h2>

                <div className="justify-end">
                  <button className="mx-4 hover:text-error" onClick={handleDelete}>
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {isExpanded && (
          <dialog id={'message_model_' + message.id} className={'modal modal-open'}>
            <div className={'bg-white modal-box w-fit max-w-[60vw]'}>
              <FileViewer iMessage={message} closeFunction={handleExpand} />
            </div>
          </dialog>
        )}
      </div>
    )
  }

  const getFullChatBubble = () => {
    let content = <></>
    // Hide the chat bubble if the message is hidden
    if (isEditing) {
      content = (
        <div style={{ minWidth: '100%', minHeight: '100%' }}>
          <MonacoEditor
            height="200px"
            language="markdown"
            theme="vs-light"
            value={messageContent[0]}
            onChange={setEditedMessage}
            options={{
              renderValidationDecorations: 'off',
              minimap: { enabled: false, autohide: true },
              selectOnLineNumbers: true,
              roundedSelection: false,
              readOnly: false,
              cursorStyle: 'line',
              automaticLayout: true,
            }}
          />
          <div className="flex justify-end items-center space-x-4 mt-2">
            <Button
              variant={'light'}
              onClick={handleCancelEdit}
              startContent={<XMarkIcon className="h-6 w-6 text-red-500 " />}
            >
              Cancel
            </Button>
            <Button
              variant={'light'}
              onClick={handleSaveEdit}
              startContent={<CheckIcon className="h-6 w-6 text-green-500" />}
            >
              Save
            </Button>
          </div>
        </div>
      )
    } else if (message.isAudio) {
      content = (
        <MicrophoneIcon className="w-6 h-6  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
      )
    } else {
      // standard message
      content = (
        <div className="space-x-1 w-full">
          {messageContent.map((item, index) => {
            return item.startsWith('<img') ? (
              <div dangerouslySetInnerHTML={{ __html: item }} key={index} />
            ) : (
              <Markdown
                key={index}
                value={item}
                message={message}
                onClickCitation={showCitation}
              ></Markdown>
              /*              <MarkdownPreview
                source={item}
                key={index}
                skipHtml={false}
                style={customStyle}
                rehypePlugins={[
                  [
                    rehypeSanitize,
                    {
                      ...defaultSchema,
                      attributes: {
                        ...defaultSchema.attributes,
                        svg: [
                          'className',
                          'hidden',
                          'viewBox',
                          'fill',
                          'height',
                          'width',
                        ],
                        path: ['fill-rule', 'd'],
                        div: [
                          'className',
                          'class',
                          'data-code',
                          ...(defaultSchema.attributes?.div || []),
                        ],
                      },
                      tagNames: [
                        ...(defaultSchema.tagNames || []),
                        'svg',
                        'path',
                        'div',
                      ],
                    },
                  ],
                ]}
                components={
                  // replace all li[id^="user-content-fn-cid-"] with Citation component
                  {
                    h2: (props) => {
                      return h2Markdown(props)
                    },
                    a: (props) => {
                      return aMarkdown(props)
                    },
                    li: (props) => {
                      return liMarkdown(props, message, (cidNumber) =>
                        showCitation(cidNumber),
                      )
                    },
                    section: (props) => {
                      return sectionMarkdown(props, message)
                    },
                    code: ({ children = [], className, ...props }) => {
                      return codeMarkdown({ children, className, props })
                    },
                  }
                }
                wrapperElement={{
                  'data-color-mode': 'light',
                }}
              />*/
            )
          })}
        </div>
      )
    }

    // no tabs for user messages
    if (message.role === 'user') {
      return (
        <>
          <ChatMessageHeader
            session={session!}
            message={message}
            onEditClick={() => setIsEditing(true)}
          ></ChatMessageHeader>
          <div
            className={`chat-bubble relative transition-background max-w-5/6 min-w-48 ${transfered ? '!bg-white' : 'bg-gray-100'}`}
          >
            {content}
            {message.isLoading && (
              <Spinner
                color={'primary'}
                size={'sm'}
                className={'rounded-full bg-white absolute right-[5px] -top-[15%]'}
              />
            )}
          </div>
        </>
      )
    }

    // message from assistant
    return (
      <>
        <ChatMessageHeader
          session={session!}
          message={message}
          disableButtons={{
            delete: session?.isAssistantRunning,
            copy: session?.isAssistantRunning,
            edit: session?.isAssistantRunning,
            regenerate: session?.isAssistantRunning,
            speech: session?.isAssistantRunning,
          }}
          hideButtons={{
            regenerate: !isLastMessage,
          }}
          onEditClick={() => setIsEditing(true)}
        />
        <div
          className={`min-w-[90%] chat-bubble transition-background ${messageError && message.id ? 'bg-warning-200' : transfered ? 'bg-white' : 'bg-gray-100'}`}
        >
          <div className="">
            <Tabs
              variant={'light'}
              aria-label="Options"
              selectedKey={selected}
              onSelectionChange={(key) => setSelected(key as string)}
            >
              <Tab key={'conversation'} title="Conversation">
                <>
                  {message.id || messageError ? (
                    <>
                      {content}
                      {messageError && (
                        <span className="text-primary rounded p-2 bg-yellow-100 italic font-bold">
                          {messageError}
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="loading loading-dots loading-sm text-primary"></span>
                  )}
                </>
              </Tab>
              {displayToolCallsTab && (
                <Tab key={'toolCalls'} title="Tool Calls">
                  <div className=" text-xs mb-2 mockup-code bg-white ">
                    {!!message.toolCalls &&
                      message.toolCalls.map((func_call, index) => (
                        <div key={index} className="m-2">
                          <ToolCall initialToolCall={func_call}></ToolCall>
                        </div>
                      ))}
                  </div>
                </Tab>
              )}
              {displaySourceFileTab && (
                <Tab key={'sourceFiles'} title="Source Files">
                  {references && (
                    <PdfViewer
                      references={references}
                      acticeReferenceId={activeReferenceId}
                    />
                  )}
                </Tab>
              )}
            </Tabs>
          </div>
          {displayFeedback && (
            <div className="absolute right-3 top-1">
              <FeedbackWidget
                initialFeedback={message.feedback}
                ratingType="stars"
                entityType="message"
                entityId={message.id || ''}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div
      className={`chat ${chatClass} group ${message.id ? '' : 'animate-create'} w-full px-2 md:px-4`}
    >
      {getFullChatBubble()}
    </div>
  )
}

export default ChatMessage
